define(['app', '$window'], (app, $window) => () => {
  const comp = {
    formDisabled: false,
    dob: null
  };

  const _channel = {
    birthdayComponent: 'loyaltyBirthdayReward/update',
    modalClose: 'modal/close'
  };

  const _select ={
    form: '.loyaltyBirthdayReward_form',
    dobInput: '.loyaltyBirthdayReward_formInput-date',
    dobButton: '.loyaltyBirthdayReward_formButton',
    dobText: '.loyaltyBirthdayReward_validDOB',
    warning: '.loyaltyBirthdayReward_warningAlert',
    error: '.loyaltyBirthdayReward_errorAlert',
    success: '.loyaltyBirthdayReward_successDialog',
    closeButton: '.loyaltyBirthdayReward_dialogClose'
  };

  const _props = {
    invalidDate: null,
    invalidFormat: null,
    unknown: null
  };

  comp.init = (element) => {
    comp.element = element;
    comp.formEl = element.querySelector(_select.form);
    comp.dobInput = element.querySelector(_select.dobInput);
    comp.dobButton = element.querySelector(_select.dobButton);
    comp.dobText = element.querySelector(_select.dobText);
    comp.warningEl = element.querySelector(_select.warning);
    comp.errorEl = element.querySelector(_select.error);
    comp.successEl = element.querySelector(_select.success);
    comp.closeButton = element.querySelector(_select.closeButton);

    comp.getProps();
    comp.addListeners();

    return comp;
  };

  comp.getProps = () => {
    Object.keys(_props).forEach(key => {
      const prop = comp.element.querySelector(`[data-property-key="${key}"]`);

      if (prop) {
        _props[key] = prop.getAttribute('data-property-value');
      }
    });
  };

  comp.addListeners = () => {
    comp.formEl.addEventListener('submit', comp.onSubmit);
    comp.closeButton.addEventListener('click', comp.closeModal);
  };

  comp.onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (comp.formDisabled) {
      return;
    }

    if (!comp.isDateValid(comp.dobInput.value)) {
      comp.showError(_props.invalidFormat);
      return;
    }

    app.ajax.post({
      url: '/confirmAttribute.profile',
      send: comp.serialize(comp.dobInput.value),
      requestHeader: {
        header: 'Content-Type',
        value: 'application/json',
      },
      success: comp.onSuccess,
      error: comp.onError,
    });

    comp.disableForm();
  };

  comp.onSuccess = () => {
    comp.enableForm(); // not really needed

    comp.formEl.setAttribute('data-show', 'false');
    comp.successEl.setAttribute('data-show', 'true');

    comp.dobText.innerHTML = comp.dobInput.value;
    app.publish(_channel.birthdayComponent, comp.dobInput.value);
  };

  comp.onError = (arg1, arg2) => {
    // interesting choice not to give the status code of the request for the error callback
    // technically it is given, but it's given as a string (like 'status 404')... why?

    // only 1 argument means status code is 400
    if (arg2 == undefined) {
      comp.showError(_props.invalidDate);
    } else {
      comp.showError(_props.unknown);
    }

    comp.enableForm();
  };

  comp.showError = (message) => {
    comp.warningEl.setAttribute('data-show', 'false');
    comp.errorEl.setAttribute('data-show', 'true');
    comp.errorEl.innerHTML = message;
  };

  comp.disableForm = () => {
    comp.formDisabled = true;
    comp.dobButton.disabled = true;
  };

  comp.enableForm = () => {
    comp.formDisabled = false;
    comp.dobButton.disabled = false;
  };

  comp.closeModal = () => {
    app.publish(_channel.modalClose, true);
  };

  comp.serialize = (dob) => JSON.stringify({
    name: 'dateOfBirth',
    value: dob,
    confirmed: 'true',
    locked: 'false'
  });

  comp.isDateValid = (dateString) => {
    // First check for the pattern
    const regex_date = /^\d{4}-\d{1,2}-\d{1,2}$/;

    if (!regex_date.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  comp._props = _props;

  return comp;
});
